.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1.3/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
  margin-left: 2rem;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  height: 100%;
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-right: 2rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  transition: var(--transition);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

.boton {
  padding-top: 10px;
}

.list_title {
  padding-bottom: 50px;
}

.projects-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(205, 167, 119);
  border: 1px solid #1f1f38;
  border-radius: 15px;
  padding: 10px;
  width: 600px;
  height: 400px; 
  z-index: 1000;  /* A high z-index to ensure the popup is above all other elements */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.projects-popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.projects-popup li {
  margin-bottom: 8px;
}

.popup-close {
  position: absolute;
  top: 5px;
  right: 1rem;
  background: none;
  border: none;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  color: #333; /* Or another suitable color */
}

.popup-close:hover {
  color: #555; /* Adjust hover color as needed */
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* ============== MEDIA QUERIES (Small DEVICES) =============== */

@media screen and (max-width: 600px) {
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}
